import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../styles/navbar.css';
import '@material/react-top-app-bar/index.scss';
import TopAppBar, {
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';
import { Headline4 } from '@material/react-typography';
import { Link } from 'react-router-dom';
import { MDCRipple } from '@material/ripple';

class SimpleNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  openDrawer() {
    this.props.dispatch({ type: 'openDrawer' });
  }

  componentDidMount() {
    const iconButtonRipple = new MDCRipple(document.querySelector('.home-button'));
    iconButtonRipple.unbounded = true;
  }

  goBack(event) {
    try {
      this.props.history.goBack();
    } catch(error) {
      console.error('Error go back ', error);
    }
  }

  render() {
    return (
      <TopAppBar fixed={true}>
        <TopAppBarRow>
          <TopAppBarSection align='start'>
            <TopAppBarIcon navIcon tabIndex={0}>
              <MaterialIcon hasRipple icon='menu' onClick={(e) => this.openDrawer(e)}/>
            </TopAppBarIcon>
            <TopAppBarTitle className="pl-0">
              <Headline4>{this.props.title}</Headline4>
            </TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection align='end' role='toolbar'>
            <TopAppBarIcon actionItem tabIndex={0}>
              <MaterialIcon aria-label="Regresar" hasRipple icon='arrow_back' onClick={(e) => this.goBack(e)} />
            </TopAppBarIcon>
            <TopAppBarIcon actionItem tabIndex={1}>
              {this.props.backHome ? (
                <Link to="/" className="material-icons mdc-top-app-bar__action-item home-button" title="Ir al Menú">home</Link>
              ) : (
                <Link to="/cart" className="material-icons mdc-top-app-bar__action-item home-button" title="Ir al Menú">shopping_cart</Link>
              )}
            </TopAppBarIcon>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  title: state.title
});
export default connect(mapStateToProps)(withRouter(SimpleNavBar));
