function addZeroToLeft(value) {
  return value > 9 ? value : '0' + value;
}

function orderDate(dateTime) {
  let d = new Date(dateTime*1000);

  const month = addZeroToLeft(d.getMonth() + 1);
  const day = addZeroToLeft(d.getDate());
  const hour = addZeroToLeft(d.getHours() > 12 ? d.getHours() - 12 : d.getHours() === 0 ? 12 : d.getHours());
  const minutes = addZeroToLeft(d.getMinutes());
  const acronym = d.getHours() >= 12 ? 'pm' : 'am';

  return `${day}-${month}-${d.getFullYear()} a las ${hour}:${minutes} ${acronym}`;
}

export {
  addZeroToLeft,
  orderDate
}
