import React from 'react';
import { Headline3 } from '@material/react-typography';
import CategoryList from '../Categories/List';
import '../../styles/sidebar.css';

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebar-menu d-none d-sm-block">
        <Headline3 className="my-1">Menú</Headline3>
        <CategoryList />
      </div>
    </div>
  );
}

export default Sidebar;
