import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../styles/products.css';
import List from '@material/react-list';
import Product from './Chunk';
import { Headline4, Body1 } from '@material/react-typography';
import UOrderApi from '../../services/uorder-api.js';

class ProductList extends React.Component {
  componentDidMount() {
    if (!this.props.products || this.props.products.length < 1) {
      this.props.dispatch({ type: 'showPreLoader' });
      if (!this.props.categories || this.props.categories.length < 1) {
        this.loadCategories();
      } else {
        this.props.dispatch({ type: 'reloadProducts' });
      }
    }
  }

  loadCategories() {
    UOrderApi.categories.index(this.props.http, this.props.companyId)
      .then((categories) => {
        this.props.dispatch({ type: 'setCategories', categories: categories });
        if (categories && categories.length > 0) {
          this.props.dispatch({ type: 'selectCategory', categoryIndex: 0 });
        }
      })
      .catch((error) => {
        console.error('Error getting categories: ', error);
        this.props.dispatch({ type: 'hidePreLoader' });
      });
  }

  selectProduct(product) {
    this.props.history.push(`/product/${product.id}`);
  }

  render() {
    if (!this.props.products || this.props.products.length < 1) {
      return (
        <div className="text-center p-2">
          <i className="fas fa-plug text-dark" style={{fontSize: '70px'}}></i>
          <Headline4>No se han podido encontrar productos.</Headline4>
          <Body1>Por verifique su conexión a internet o intente seleccionando otra categoría.</Body1>
        </div>
      )
    }

    return (
      <List className="product-list" role="list">
        {
          this.props.products.map((p, i) => {
            return (
              <Product key={`${i}-${p.name}`} image={p.image_url} name={p.name} description={p.description} price={p.price} onClick={() => this.selectProduct(p)} />
            );
          })
        }
      </List>
    );
  }
}

const mapStateToProps = (state) => ({
  searchTerm: state.searchTerm,
  categories: state.categories,
  products: state.products,
  http: state.$http,
  companyId: state.companyId
});
export default connect(mapStateToProps)(withRouter(ProductList));
