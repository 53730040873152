import React from 'react';
import NavBar from './Navbar.js';

class Main extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="mdc-top-app-bar--fixed-adjust complex">
          <div className="container-fluid">
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
