import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import TextField, { Input } from '@material/react-text-field';
import MaterialIcon from '@material/react-material-icon';
import Button from '@material/react-button';
import SimpleLayout from '../components/Layouts/Simple.js';
import { Headline4 } from '@material/react-typography';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import Auth from '../utils/auth';
import UOrderApi from '../services/uorder-api';

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickLoginWithFacebook = this.handleClickLoginWithFacebook.bind(this);
    this.callbackAfterLoginWithGoogle = this.callbackAfterLoginWithGoogle.bind(this);
    this.callbackAfterLoginWithFacebook = this.callbackAfterLoginWithFacebook.bind(this);
    this.loginWithGoogle = this.loginWithGoogle.bind(this);
    this.failLoginWithGoogle = this.failLoginWithGoogle.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.dispatch({ type: 'showPreLoader' });
    const redirectTo = this.props.location.state && this.props.location.state.from ? this.props.location.state.from.pathname : '/';
    Auth.login(this, { email: this.state.email, password: this.state.password }, redirectTo);
  }

  handleClickLoginWithFacebook(r) {
    this.props.dispatch({ type: 'showPreLoader' });
  }

  callbackAfterLoginWithFacebook(facebookResponse) {
    if (facebookResponse.status !== 'unknown') {
      this.loginWithFacebook(facebookResponse.id, facebookResponse.accessToken, facebookResponse.email);
    } else {
      this.props.dispatch({ type: 'hidePreLoader' });
      this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'No hemos podido acceder a tu cuenta.', type: 'error' } });
    }
  }

  callbackAfterLoginWithGoogle(googleResponse) {
    this.props.dispatch({ type: 'showPreLoader' });
    this.loginWithGoogle(googleResponse.El, googleResponse.Zi.access_token, googleResponse.w3.U3, googleResponse.w3.Paa, googleResponse.w3.ofa, googleResponse.w3.wea);
  }

  failLoginWithGoogle(params) {
    console.log('Fallo la conexión con Google ', params);
    this.props.dispatch({ type: 'hidePreLoader' });
    this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'No hemos podido acceder a tu cuenta.', type: 'error' } });
  }

  loginWithFacebook(id, accessToken, email = '') {
    UOrderApi.customers.login_with_facebook(this.props.$http, {
      company_id: this.props.companyId,
      f: {
        id: id,
        access_token: accessToken,
        email: email
      }
    })
      .then((response) => {
        Auth.authenticateCustomer(this, response.jwt);

        if (response.action === 'user_must_complete_the_register') {
          this.props.history.push('/address/new');
          this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Por favor ingresa tu dirección.' } });
        } else {
          const redirectTo = this.props.location.state && this.props.location.state.from ? this.props.location.state.from.pathname : '/';
          this.props.history.push(redirectTo);
          this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Haz iniciado sesión satisfactoriamente.' } });
        }
        this.props.dispatch({ type: 'hidePreLoader' });
      })
      .catch((error) => {
        this.props.dispatch({ type: 'hidePreLoader' });
        this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'No hemos podido acceder a tu cuenta.', type: 'error' } });
        console.error(error.response);
      });
  }

  loginWithGoogle(id, accessToken, email = '', avatarUrl = '', firstName = '', lastName = '') {
    UOrderApi.customers.login_with_google(this.props.$http, {
      company_id: this.props.companyId,
      g: {
        id: id,
        access_token: accessToken,
        email: email,
        avatar_url: avatarUrl,
        first_name: firstName,
        last_name: lastName
      }
    })
      .then((response) => {
        Auth.authenticateCustomer(this, response.jwt);

        if (response.action === 'user_must_complete_the_register') {
          this.props.history.push('/address/new');
          this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Por favor ingresa tu dirección.' } });
        } else {
          const redirectTo = this.props.location.state && this.props.location.state.from ? this.props.location.state.from.pathname : '/';
          this.props.history.push(redirectTo);
          this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Haz iniciado sesión satisfactoriamente.' } });
        }
        this.props.dispatch({ type: 'hidePreLoader' });
      })
      .catch((error) => {
        this.props.dispatch({ type: 'hidePreLoader' });
        this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'No hemos podido acceder a tu cuenta.', type: 'error' } });
        console.error(error.response);
      });
  }

  render() {
    if (Auth.isAuthenticated(this.props)) {
      const redirectTo = this.props.location.state && this.props.location.state.from ? this.props.location.state.from.pathname : '/';
      return <Redirect to={redirectTo} />;
    }

    return (
      <SimpleLayout>
        <div className="narrow-form p-2">
          <form onSubmit={this.handleSubmit}>
            <Headline4 className="my-2">Inicio de Sesión</Headline4>
            <div className="mb-1">
              <TextField
                outlined
                label='Email'
                className="w-100 mb-2"
                leadingIcon={<MaterialIcon icon="email"/>}
              ><Input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required={true}
              />
              </TextField>

              <TextField
                outlined
                label='Contraseña'
                className="w-100"
                leadingIcon={<MaterialIcon icon="vpn_key"/>}
              ><Input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                required={true}
              />
              </TextField>
            </div>

            <Button raised className="w-100">Iniciar Sesión</Button>
          </form>

          <div className="text-center my-2">
            ¿No tienes una cuenta?&nbsp;
            <Link to='/sign_up'>Registrate</Link>
          </div>

          <p className="text-center my-2">o</p>

          <div className="text-center mt-2">
            <FacebookLogin
              appId="944336992587052"
              autoLoad={false}
              callback={this.callbackAfterLoginWithFacebook}
              onClick={this.handleClickLoginWithFacebook}
              fields="first_name,last_name,email"
              version='4.0'
              language='es_LA'
              render={renderProps => (
                <Button raised style={{backgroundColor: '#3F65B3'}} className="text-white mt-2" onClick={renderProps.onClick}><i className="fab fa-facebook-f"></i>&nbsp;Inicia sesión con Facebook</Button>
              )}
            />
          </div>

          <div className="text-center mt-2">
            <GoogleLogin
              clientId="71760999984-k7rvas9giuae4nnumdk4hfk2h9a028j8.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={this.callbackAfterLoginWithGoogle}
              onFailure={this.failLoginWithGoogle}
              render={renderProps => (
                <Button raised style={{backgroundColor: '#EA4335'}} className="text-white mt-2" onClick={renderProps.onClick} disabled={renderProps.disabled}><i className="fab fa-google"></i>&nbsp;Inicia sesión con Google</Button>
              )}
            />
          </div>
        </div>
      </SimpleLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  $http: state.$http,
  companyId: state.companyId
});
export default connect(mapStateToProps)(withRouter(LogIn));
