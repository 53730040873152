import React from 'react';
import SimpleNavBar from './SimpleNavbar.js';

function SimpleLayout(props) {
  return (
    <React.Fragment>
      <SimpleNavBar backHome={props.backHome} />
      <div className="mdc-top-app-bar--fixed-adjust">
        {props.children}
      </div>
    </React.Fragment>
  );
}

export default SimpleLayout;
