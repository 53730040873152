import React from 'react';
import ApplicationLayout from '../components/Layouts/Application.js';
import {Cell, Grid, Row} from '@material/react-layout-grid';
import ProductList from '../components/Products/List.js';
import Sidebar from '../components/Layouts/Sidebar.js';

function Home() {
  return (
    <ApplicationLayout>
      <Grid>
        <Row>
          <Cell desktopColumns={4} phoneColumns={4} tabletColumns={3}>
            <Sidebar />
          </Cell>
          <Cell desktopColumns={8} phoneColumns={4} tabletColumns={5}>
            <ProductList />
          </Cell>
        </Row>
      </Grid>
    </ApplicationLayout>
  );
}

export default Home;
