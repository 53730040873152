import React from 'react';
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';
import { ListItem } from '@material/react-list';
import {
  Headline5,
  Headline6
} from '@material/react-typography';
import { convertToCurrency } from '../../filters/currency';
import SecureImage from '../../components/Common/SecureImage';

class Chunk extends React.Component {
  cutText(text) {
    let cutText = text
    if (text.length > 80) {
      cutText = text.slice(0, 80) + '...';
    }

    return cutText;
  }

  render() {
    return (
      <ListItem title="Agregar" onClick={this.props.onClick}>
        <div className="product">
          <div className="d-flex flex-row align-items-center">
            <div className="product__preview">
              <SecureImage src={this.props.image} alt="preview" />
            </div>
            <div className="product__info my-1">
              <div className="product__name-and-price">
                <div>
                  <Headline5 className="my-0 text-capitalize">{this.props.name}</Headline5>
                </div>
                <div className="product__price">
                  <Headline6 className="my-0">{convertToCurrency(this.props.price)}</Headline6>
                </div>
              </div>
              <div className="product__description-and-action">
                <div className="product__description text-justify">{this.cutText(this.props.description)}</div>
                {
                  this.props.showAddButton && (
                    <div className="product__action ml-2">
                      <Fab icon={<MaterialIcon icon='add' />} mini style={{backgroundColor: 'var(--mdc-theme-primary)'}} title="Agregar" />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          {this.props.customFooter}
        </div>
      </ListItem>
    );
  }
}

export default Chunk;
