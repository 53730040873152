import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer, { DrawerHeader } from '@material/react-drawer';
import List, {ListItem, ListItemText, ListItemGraphic, ListDivider} from '@material/react-list';
import MaterialIcon from '@material/react-material-icon';
import { Route, Link } from 'react-router-dom';
import Auth from '../../utils/auth';

class Aside extends React.Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  closeDrawer() {
    this.props.dispatch({ type: 'closeDrawer' });
  }

  logout(e) {
    this.props.dispatch({ type: 'showPreLoader' });
    this.closeDrawer();
    Auth.logout(this);
    this.props.dispatch({ type: 'hidePreLoader' });
  }

  goToPromos() {
    try {
      this.props.dispatch({ type: 'showPreLoader' });
      this.closeDrawer();

      let indexCategory = -1;
      this.props.categories.forEach((c, i) => {
        if(c.name.match(new RegExp('Promos', 'i')) !== null) {
          indexCategory = i;
        }
      });
      if (indexCategory >= 0) {
        this.props.dispatch({ type: 'selectCategory', categoryIndex: indexCategory });
        this.props.history.push('/');
      } else {
        this.props.dispatch({ type: 'hidePreLoader' });
        this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'No tenemos promociones por el momento.', type: 'warning' } });
      }
    } catch (error) {
      this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Error obteniendo promociones', type: 'error' } });
      console.error('Showing promos ', error);
    }
  }

  logInOrLogOut() {
    if (Auth.isAuthenticated(this.props)) {
      return (
        <React.Fragment>
          <Route
            path='/my_orders'
            children={({ match }) => (
              <Link to='/my_orders' onClick={(e) => this.closeDrawer()}>
                <ListItem className={match ? 'mdc-list-item--activated' : ''}>
                  <ListItemGraphic graphic={<MaterialIcon icon='folder'/>} />
                  <ListItemText primaryText='Mis Ordenes' />
                </ListItem>
              </Link>
            )}
          />
          <Route
            path='/my_addresses'
            children={({ match }) => (
              <Link to='/my_addresses' onClick={(e) => this.closeDrawer()}>
                <ListItem className={match ? 'mdc-list-item--activated' : ''}>
                  <ListItemGraphic graphic={<MaterialIcon icon='my_location'/>} />
                  <ListItemText primaryText='Mis Direcciones' />
                </ListItem>
              </Link>
            )}
          />
          <ListItem onClick={this.logout}>
            <ListItemGraphic graphic={<MaterialIcon icon='power_settings_new'/>} />
            <ListItemText primaryText='Cerrar Sesión' />
          </ListItem>
        </React.Fragment>
      )
    }

    return (
      <Route
        path='/login'
        children={({ match }) => (
          <Link to='/login' onClick={(e) => this.closeDrawer()}>
            <ListItem className={match ? 'mdc-list-item--activated' : ''}>
              <ListItemGraphic graphic={<MaterialIcon icon='account_circle'/>} />
              <ListItemText primaryText='Iniciar Sesión' />
            </ListItem>
          </Link>
        )}
      />
    )
  }

  render() {
    return (
      <Drawer
        modal
        open={this.props.drawerIsOpened}
        onClose={(e) => this.closeDrawer()}
      >
        <DrawerHeader className="text-center border-bottom pt-2">
          <img src={this.props.company.menu_logo_url ? this.props.company.menu_logo_url : this.props.company.logo_url} alt="" />
        </DrawerHeader>

        <div className="mdc-drawer__content">
          <List>
            <Route
              path='/search'
              children={({ match }) => (
                <Link to='/search' onClick={(e) => this.closeDrawer()}>
                  <ListItem className={match ? 'mdc-list-item--activated' : ''}>
                    <ListItemGraphic graphic={<MaterialIcon icon='search'/>} />
                    <ListItemText primaryText='Búsqueda' />
                  </ListItem>
                </Link>
              )}
            />
            <ListItem onClick={(e) => this.goToPromos(e)}>
              <ListItemGraphic graphic={<MaterialIcon icon='local_offer'/>} />
              <ListItemText primaryText='Promociones' />
            </ListItem>
            <Route
              path='/branch'
              children={({ match }) => (
                <Link to='/branch' onClick={(e) => this.closeDrawer()}>
                  <ListItem className={match ? 'mdc-list-item--activated' : ''}>
                    <ListItemGraphic graphic={<MaterialIcon icon='edit_location'/>} />
                    <ListItemText primaryText='Cambiar Sitio' />
                  </ListItem>
                </Link>
              )}
            />
            <ListDivider />
            {this.logInOrLogOut()}
            <ListDivider />
            <Route
              path='/feedback'
              children={({ match }) => (
                <Link to='/feedback' onClick={(e) => this.closeDrawer()}>
                  <ListItem className={match ? 'mdc-list-item--activated' : ''}>
                    <ListItemGraphic graphic={<MaterialIcon icon='feedback'/>} />
                    <ListItemText primaryText='Envíanos tus comentarios' />
                  </ListItem>
                </Link>
              )}
            />
            <Route
              path='/info'
              children={({ match }) => (
                <Link to='/info' onClick={(e) => this.closeDrawer()}>
                  <ListItem className={match ? 'mdc-list-item--activated' : ''}>
                    <ListItemGraphic graphic={<MaterialIcon icon='info'/>} />
                    <ListItemText primaryText='Acerca de' />
                  </ListItem>
                </Link>
              )}
            />
          </List>
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  drawerIsOpened: state.drawerIsOpened,
  categories: state.categories,
  $http: state.$http,
  company: state.company
});
export default connect(mapStateToProps)(withRouter(Aside));
